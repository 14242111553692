import '../css/header.css';
import logo from "../assets/img/logo.png";
import {Link, NavLink} from "react-router-dom";
import Button from "./Button";
import { ReactComponent as Discord } from '../assets/icons/discord.svg';
import { ReactComponent as Kick} from "../assets/icons/kick-logo.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuth} from "../AuthProvider";
import {ReactComponent as HamburgerMenu} from "../assets/icons/hamburger-menu.svg";
import {ReactComponent as Close} from "../assets/icons/close.svg";
import {ReactComponent as ChevronRight} from "../assets/icons/chevron-right.svg";

export default function Header(){
    const {user} = useAuth();
    const auth = useAuth();
    const kick = process.env.REACT_APP_KICK_API;
    const discord_url = process.env.REACT_APP_DISCORD_URL_LOGIN;
    const [hamburgerMenu, setHamburgerMenu] = useState(false);
    const [profileMenuOpened, setProfileMenuOpened] = useState(false);
    const [kickStatus, setKickStatus] = useState(null);

    useEffect(() => {

        axios.get(kick,{
            headers: {
                'Content-type': 'application/json'
            }
        })
            .then(response =>{
                setKickStatus(response.data);
            })
            .catch(error =>{
                console.log(error);
            })
    }, []);

    return(
        <header>
            <div className="container">
                <div className="logo-wrapper">
                    <NavLink to={"/"}>
                        <img className="logo" src={logo} alt={"buhlockay logo"}/>
                    </NavLink>
                </div>
                <nav className="navigation-menu">
                <ul>
                    <li>
                        <NavLink
                            to={"/"}
                            className={({ isActive, isPending }) =>
                                 isActive ? "active" : ""
                             }
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"wagers_leaderboard"}>
                            Leaderboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"rewards"}>
                            Rewards
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"bonus_hunt"}>
                            Bonus hunt
                        </NavLink>
                    </li>
                </ul>
            </nav>
                <div className={"action-content"}>
                    <a href="https://kick.com/buhlockay" className={`kick-status ${kickStatus?.livestream ? "online" : "offline"}`} target="_blank" rel="noreferrer">
                        <div className="animated-kick-logo">
                            <span className="circle-status"></span>
                            <Kick className="kick-logo"/>
                        </div>
                        <span className="text-status">{kickStatus?.livestream ? "LIVE" : "OFFLINE"}</span>
                    </a>
                    {user.username ?
                        <div className="profile-btn">
                            <div style={{display:"flex", gridColumnGap:"8px"}}>
                                <img
                                    style={{borderRadius:"6px"}}
                                    src={user.avatar} width="36" height="36"/>
                                <p>{user.username}</p>
                            </div>
                            <ul className="profile-menu-dropdown">
                                <NavLink to={"me"}>
                                    <li>
                                        My profile
                                    </li>
                                </NavLink>
                                {
                                    user.role === 2 ? <NavLink to={"admin"}>
                                            <li>
                                                Admin
                                            </li>
                                        </NavLink>
                                        : null
                                }

                                <li onClick={() => auth.logOut()}>
                                    Log out
                                </li>
                            </ul>
                        </div>
                        :
                        <a href={discord_url} target="_blank" rel='noreferrer' style={{flexShrink:0}}>
                            <Button>
                                <p className="text">Log in w/</p>
                                <Discord/>
                            </Button>
                        </a>
                    }
                    <div className={`phone-menu ${hamburgerMenu ? "opened" : "closed"}`}>
                        <div className="hamburger-header">
                            <h5 style={{fontWeight:"700"}}>Menu</h5>
                            <Close
                                className="close-btn"
                                onClick={()=>{
                                    setHamburgerMenu(false);
                                }}
                            />
                        </div>
                        <div className="hamburger-body">
                            <ul>
                                <li>
                                    <NavLink to={"/"}
                                             onClick={()=>{
                                                 setHamburgerMenu(false);
                                             }}
                                    >
                                        Home
                                        <ChevronRight className={"chevron-right"}/>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"wagers_leaderboard"}
                                             onClick={()=>{
                                                 setHamburgerMenu(false);
                                             }}>
                                        Leaderboard
                                        <ChevronRight className={"chevron-right"}/>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"rewards"}
                                             onClick={()=>{
                                                 setHamburgerMenu(false);
                                             }}
                                    >
                                        Rewards
                                        <ChevronRight className={"chevron-right"}/>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"bonus_hunt"}
                                             onClick={()=>{
                                                 setHamburgerMenu(false);
                                             }}
                                    >
                                        Bonus hunt
                                        <ChevronRight className={"chevron-right"}/>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <HamburgerMenu
                        onClick={()=>{
                            setHamburgerMenu(prevState => !prevState);
                        }}
                    className="hamburger-menu"
                    />
                </div>
            </div>
        </header>
    );
}