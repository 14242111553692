import "../css/rewards-page.css";
import heroImage from "../assets/img/buhlockay-hero-image.png";
import Button from "../components/Button";
import bcGameScreen from "../assets/img/slotfi-screen.png";
import slotfiLogo from "../assets/img/slotfi-logo.png";
import signupCode from "../assets/img/signup-code.png";
import vipbonus from "../assets/img/vip-bonus.png";
import table from "../assets/img/table.png";
import { ReactComponent as BlurredBg } from '../assets/icons/blurred-bg.svg';
import { ReactComponent as Copy } from '../assets/icons/copy.svg';
import depositFont from "../assets/img/desposit-bonut-font.png";
import vipBonusFont from "../assets/img/vip-bonus-font.png";
import wagersRaceFont from "../assets/img/wager-race-font.png";
import signupBcGameScreen from "../assets/img/signup-slotfi.png";

export default function Rewards(){
    const mulletUrl = "https://slotfi.game/?ch=0&ic=maxwin#/register";

    return(
        <div className="rewards page">
            <div className="rewards-hero-image container">
                <div className="left-block">
                    <h3 className="heading">Get exclusive rewards & much bonuses on  <img className="bc-game-logo" src={slotfiLogo} alt="bc game logo"/></h3>
                    <p>High payouts with small deposit. Play through the highly rewarding offers using the promo code.</p>
                    {/*<img className="bc-game-logo" src={slotfiLogo} alt="bc game logo"/>*/}
                    <Button onClick={()=>{
                        window.open(mulletUrl,'_blank','noopener,noreferrer');
                    }}><p className="text">Use promo code</p></Button>
                </div>
                <div className="right-block">
                    <img className="rewards-hero-image" src={bcGameScreen} width="100%" alt="rewards hero image"/>
                    <BlurredBg className="blurred-bg"/>
                </div>
            </div>

            <div className="rewards-info container">
                <div className="rewards-heading">
                    <h3 className="heading">Choose <img className="bc-game-logo" src={slotfiLogo} alt="bc game logo"/> now!</h3>
                    <p className="description">Claim bonuses now using promo code:</p>
                    <p className="mullet-code">
                        <span style={{marginRight:"8px"}}>MAXWIN</span>
                        <span className="copy-button" onClick={()=>{
                            navigator.clipboard.writeText("MAXWIN");
                            // Alert the copied text
                            alert("Copied the text: " + "MAXWIN");
                        }}>
                            <Copy/>
                        </span>
                    </p>
                </div>
                <ul className="rewards-list">
                    <li className="deposit-bonus-cardinfo">
                        <img className="signup-code" src={signupCode} alt="sign up code image"/>
                        <img className="deposit-bonus-font-png" src={depositFont} alt="deposit image"/>
                        <div className="cyan blurred-bg"></div>
                        <p>Claim Your Deposit Reward Now!</p>
                        <Button
                            onClick={()=>{
                                window.open(mulletUrl,'_blank','noopener,noreferrer');
                            }}
                        ><p className="text">Use promo code</p></Button>
                    </li>
                    <li className="vip-bonus-cardinfo">
                        <img className="roulette" src={vipbonus} alt="roulette image"/>
                        <img className="vip-bonus-font-png" src={vipBonusFont} alt="vip bonus image"/>
                        <div className="orange blurred-bg"></div>
                        <p>Earn Cash prizes with VIP Level achieved. Reach VIP level 17 to unlock Weekly/Monthly Bonus and Lossback</p>
                        <Button
                            onClick={()=>{
                                window.open(mulletUrl,'_blank','noopener,noreferrer');
                            }}
                        ><p className="text">Use promo code</p></Button>
                    </li>
                    <li className="wager-race-cardinfo">
                        <img className="table-img" src={table} alt="table image"/>
                        <img className="wagers-race-font-png" src={wagersRaceFont} alt="wagers race image"/>
                        <div className="purple blurred-bg"></div>
                        <p>Weekly payouts for winners of wager race</p>
                        <Button
                            onClick={()=>{
                                window.open(mulletUrl,'_blank','noopener,noreferrer');
                            }}
                        ><p className="text">Use promo code</p></Button>

                    </li>
                </ul>
            </div>

            <div className="container how-to-register">
                <h3 className="heading">How to register</h3>
                <div className="two-column">
                    <div className="left-block">
                        <img src={signupBcGameScreen} alt="bc game sign up image"/>
                    </div>
                    <div className="right-block">
                        <ul className="how-to-register-list">
                            <li>
                                <span className="circle">1</span>
                                <div className="box">
                                    Fill in email and login password infomation
                                </div>
                            </li>
                            <li className="vertical-line"></li>
                            <li>
                                <span className="circle">2</span>
                                <div className="box">
                                    Enter promo code MAXWIN
                                    <span className="copy-button" onClick={()=>{
                                        navigator.clipboard.writeText("MAXWIN");
                                        // Alert the copied text
                                        alert("Copied the text: " + "MAXWIN");
                                    }}>
                            <Copy/>
                        </span>
                                </div>
                            </li>
                            <li className="vertical-line"></li>
                            <li>
                                <span className="circle">3</span>
                                <div className="box">
                                    Agree to conditions and click on “Sign up” button
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}